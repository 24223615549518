import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [0,2,7,11,13];

export const dictionary = {
		"/(legacy-layout)": [~18,[3]],
		"/(hud)/hud": [~14,[2]],
		"/(hud)/hud/custom": [~15,[2]],
		"/(hud)/hud/custom/edit/[hudPackageId]": [~16,[2]],
		"/(hud)/hud/my-skins": [~17,[2]],
		"/(legacy-layout)/login": [~22,[3]],
		"/(legacy-layout)/logout": [23,[3]],
		"/(new-layout)/(membership)/membership": [~42,[9]],
		"/(new-layout)/(membership)/membership/enterprise": [43,[9]],
		"/(new-layout)/(membership)/membership/partner": [~44,[9]],
		"/(new-layout)/(membership)/membership/pay-redirect/non-auth/[planNameId]/[customerUid]": [~45,[9]],
		"/(new-layout)/(membership)/membership/pay-redirect/onetime": [~46,[9]],
		"/(new-layout)/(membership)/membership/pay-redirect/update-payment-method/[customerUid]": [~47,[9]],
		"/(legacy-layout)/member/password_reset/[id]": [~24,[3,5]],
		"/(legacy-layout)/member/recovery_password": [~25,[3,6]],
		"/(legacy-layout)/my-page/(home)": [~26,[3,7]],
		"/(legacy-layout)/my-page/highlight": [~27,[3,7]],
		"/(legacy-layout)/my-page/info": [~28,[3,7]],
		"/(legacy-layout)/my-page/info/password": [29,[3,7]],
		"/(legacy-layout)/my-page/membership": [~30,[3,7]],
		"/(legacy-layout)/my-page/membership/payment": [~31,[3,7]],
		"/(legacy-layout)/my-page/membership/trial-coupon": [~32,[3,7]],
		"/(legacy-layout)/oauth/authorize": [~33,[3]],
		"/(legacy-layout)/oauth/error": [34,[3]],
		"/(partner-company)/partner/[partnerPath]": [~48,[10,11]],
		"/(partner-company)/partner/[partnerPath]/admin/apply": [49,[10,11,12]],
		"/(partner-company)/partner/[partnerPath]/admin/stats": [50,[10,11,12]],
		"/(partner-company)/partner/[partnerPath]/login": [51,[10,11]],
		"/(legacy-layout)/ps-oauth-login": [~35,[3]],
		"/(legacy-layout)/(ps_admin)/ps_admin/(main)": [19,[3,4]],
		"/(legacy-layout)/(ps_admin)/ps_admin/summoner": [20,[3,4]],
		"/(report)/report": [~52,[13]],
		"/(legacy-layout)/riot-unlink": [~39,[3]],
		"/(legacy-layout)/riot": [~36,[3]],
		"/(legacy-layout)/riot/oauth-callback": [~37,[3]],
		"/(legacy-layout)/riot/oauth-logged-out": [~38,[3]],
		"/(legacy-layout)/signup": [~40,[3,8]],
		"/(legacy-layout)/signup/activate/[userId]/[code]": [~41,[3,8]],
		"/(legacy-layout)/(terms)/terms/use": [21,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';